import axios from 'axios'; 

// Create an Axios instance
export const apiInstance = axios.create({
    baseURL: process.env.REACT_APP_ONET_API_URL, // Replace with your API's base URL
    timeout: 10000 // Set a timeout if needed
});

// Add a request interceptor to append query to params
apiInstance.interceptors.request.use(
    (config) => {
        // Modify the config before the request is sent
        // Assuming you want to append a query, e.g., 'search=term'
        const additionalParams = { client: process.env.REACT_APP_ONET_USERNAME }; // Modify as needed

        // Merge additional params with existing ones
        config.params = { ...config.params, ...additionalParams };

        return config;
    },
    (error) => {
        // Handle request error here
        return Promise.reject(error);
    }
);

export default {
    searchCareers: `/ws/mnm/search`,
    browseCareerByIndustry: `/ws/mnm/browse`,
    thirtyQuestionUrl: `/ws/mnm/interestprofiler/questions_30?start=0&end=30`,
    sixtyQuestionUrl : `/ws/mnm/interestprofiler/questions?start=0&end=60` 
};
