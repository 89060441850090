import React from 'react';
import { FaLogo, FaWhiteLogo } from '../images/svg';

const Loader = ({ style = null, isLogoWhite = false }) => {
    return (
        <div className={`flex flex-col justify-center items-center w-full  ${style || 'min-h-screen'}`}>
            {isLogoWhite ? <FaWhiteLogo className={`w-32 h-32 mb-2`} /> : <FaLogo className={`w-32 h-32 mb-2`} />}
            <div id="loader-container"></div>
        </div>
    );
};

export default Loader;
