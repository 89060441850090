import { toast } from 'react-toastify';
import useJwt from '../Auth/jwt/useJwt';
import { apiInstance } from '../Auth/oNetConfig';
import axios from 'axios';
import { CareerOneStopApiKey } from './constants';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';

export const handleLogout = async () => {
    try {
        const accessToken = useJwt.getToken();
        const refresh = useJwt.getRefreshToken();
        await useJwt.logout({ access_token: accessToken, refresh_token: refresh });
        localStorage.clear();
        sessionStorage.clear();
    } catch (error) {
        toast.error(error?.response?.data?.message || 'Something went wrong');
    }
};

export const updateAnswer = ({ questionId, answerId }) => {
    const answers = localStorage.getItem('answers');
    const answersObj = answers ? JSON.parse(answers) : { answers: [] };
    const index = answersObj.answers.findIndex((ans) => ans.question_id == questionId);
    if (index == -1) {
        answersObj.answers.push({
            answer_id: String(answerId),
            question_id: String(questionId)
        });
    } else {
        answersObj.answers[index] = {
            answer_id: String(answerId),
            question_id: String(questionId)
        };
    }

    const newPayload = JSON.stringify(answersObj);
    localStorage.setItem('answers', newPayload);
};

export const fetchData = async (url, setLoading = () => {}, setError = () => {}) => {
    try {
        setLoading(true);
        const response = await apiInstance.get(url); 
        setLoading(false);
        return { data: response.data, error: null };
    } catch (err) {
        setLoading(false);
        setError(err);
        return { data: null, error: err };
    }
};

export const fetchCareerOneStopData = async (url) => {
    try {
        const response = await axios.get(url, {
            headers: { Authorization: `Bearer ${CareerOneStopApiKey}` }
        });
        return { data: response.data, error: null };
    } catch (err) {
        return { data: null, error: err };
    }
};

export function groupCareersByAlphabet(careers) {
    // Define the groups with their respective ranges
    const groups = [
        { group: 'A-C', range: /^[A-C]/i, data: [] },
        { group: 'D-G', range: /^[D-G]/i, data: [] },
        { group: 'H-M', range: /^[H-M]/i, data: [] },
        { group: 'N-R', range: /^[N-R]/i, data: [] },
        { group: 'S-Z', range: /^[S-Z]/i, data: [] }
    ];

    // Iterate over the careers array and assign each career to the appropriate group
    careers.forEach((career) => {
        const title = career.title;
        const group = groups.find((g) => g.range.test(title));
        if (group) {
            group.data.push(career);
        }
    });

    // Return only the groups with data
    return groups.filter((group) => group.data.length > 0);
}

export const downloadFile = (tableId, format = 'csv', filename = 'data') => {
    // Get table element
    const table = document.getElementById(tableId);

    let blob;
    if (format === 'xlsx') {
        // Convert table to workbook for XLS
        const wb = XLSX.utils.table_to_book(table);
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        blob = new Blob([wbout], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
    } else {
        // Convert table to CSV
        const csv = Papa.unparse(table);
        blob = new Blob([csv], { type: 'text/csv' });
    }

    // Create a link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${filename}.${format}`;
    // Trigger the download by programmatically clicking the link
    link.click();
};

export const scrollToSection = (group) => {
    const section = document.getElementById(group);
    if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
    }
};



const interestAreasLength = 6;
const maxScore = 40;
export const checkIfValid = (scoresArray) => {
    if (scoresArray.length == interestAreasLength) {
        for (let score of scoresArray) {
            if (isNaN(score) || score > maxScore) {
                return false
            }
        }
        return true;
    }
    return false
}
 

export const isScreenSmall = (currentBreakPoint) => (currentBreakPoint == 'md' || currentBreakPoint == 'sm' || currentBreakPoint == 'xs')


export function assignHouse(scores) {
    // Define the weight multipliers for each interest area and house
    const weights = {
        Realistic: { LION: 1.5, SEAHORSE: 1.2, EAGLE: 0.8 },
        Investigative: { EAGLE: 1.5, LION: 1.1, SEAHORSE: 0.9 },
        Artistic: { SEAHORSE: 1.5, EAGLE: 1.2, LION: 0.8 },
        Social: { EAGLE: 1.4, LION: 1.2, SEAHORSE: 1.0 },
        Enterprising: { LION: 1.6, EAGLE: 1.1, SEAHORSE: 0.7 },
        Conventional: { SEAHORSE: 1.6, LION: 1.1, EAGLE: 0.7 }
    };

    // Initialize cumulative house scores
    const houseScores = {
        LION: 0,
        EAGLE: 0,
        SEAHORSE: 0
    };

    // Calculate total scores for each house based on the interest areas and scores
    scores.forEach(item => {
        const areaWeights = weights[item.area];
        for (let house in areaWeights) {
            houseScores[house] += item.score * areaWeights[house];
        }
    });
 
    // Find the house with the maximum score
    let bestHouse = Object.keys(houseScores).reduce((maxHouse, currentHouse) =>
        houseScores[currentHouse] > houseScores[maxHouse] ? currentHouse : maxHouse
    );

    return bestHouse;
}
