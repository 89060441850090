// ** Reducers Imports
import industries from './careerIndustries';
import questions from './questions';
import summary from './careerSummary';
import careers from './careers';
import profiler from './profiler'
import navbar from './navbar';
import common from './common';
const rootReducer = {
    questions,
    industries,
    summary,
    careers,
    profiler,
    navbar,
    common
};

export default rootReducer;
