import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { handleWindowResize } from '../../redux/navbar';

// Utility to debounce the window resize event
function debounce(func, delay) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), delay);
  };
}

const useMediaQuery = () => {
  const dispatch = useDispatch()

  // Function to determine the current breakpoint
  const getBreakpoint = (width) => {
    if (width >= 1280) {
      dispatch(handleWindowResize({ isSidebarOpen: true, currentBreakPoint: 'xl' }));
    } else if (width >= 1024) {
      dispatch(handleWindowResize({ isSidebarOpen: true, currentBreakPoint: 'lg' }));
    } else if (width >= 768) {
      dispatch(handleWindowResize({ isSidebarOpen: false, currentBreakPoint: 'md' }));
    } else if (width >= 640) {
      dispatch(handleWindowResize({ isSidebarOpen: false, currentBreakPoint: 'sm' }));
    } else {
      dispatch(handleWindowResize({ isSidebarOpen: false, currentBreakPoint: 'xs' }));
    }
  };

  useEffect(() => {
    // Debounced resize handler
    const handleResize = debounce(() => {
      const width = window.innerWidth;
      getBreakpoint(width); 
    }, 1000);

    // Set the initial breakpoint
    handleResize();

    // Add the resize event listener
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return null;
};

export default useMediaQuery