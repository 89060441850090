import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './Contexts/AuthContext'; 
import Loader from './components/Loader';
import './blackScreen.css'
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import AppRoutes from './Routes/Router';
import ScrollToTop from './components/ScrollToTop';
import useMediaQuery from './utility/hooks/useMediaQuery';
import 'react-confirm-alert/src/react-confirm-alert.css';

const App = () => {
    useMediaQuery()
    useEffect(() => {
        AOS.init({
            duration: 1000,
            offset: 0, // Offset from the original trigger point
            easing: 'ease-in-out', // Default easing for AOS animations
            once: true, // Whether animation should happen only once or every time you scroll up/down
        });
    }, []);

    return (
        <AuthProvider>
            <Router>
                <ScrollToTop />
                <Suspense fallback={<Loader />}>
                    <AppRoutes />
                </Suspense>
            </Router>
        </AuthProvider>
    );
};

export default App;
