// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import useJwt from '../Auth/jwt/useJwt'; 

export const getLocations = createAsyncThunk('locations/getLocations', async () => {
    try {
        const response = await useJwt.getLocations();
        return {
            data: response?.data?.data || []
        };
    } catch (error) {
        return {
            error: error?.response?.data?.message || 'Something went wrong'
        };
    }
});

export const getGlobeData = createAsyncThunk('locations/getGlobeData' , async () => {
    try {
        const response = await useJwt.globeData() 
        return {
            data : response?.data?.data || []
        }
    } catch (error) {
        return {
            error: error?.response?.data?.message || 'Something went wrong'
        };
    }
})
 
export const locationsSlice = createSlice({
    name: 'locations',
    initialState: {
        loading: false,
        locations: [],
        globeData : {
            data : [],
            loading : false
        }
    }, 
    extraReducers: (builder) => {
        builder
            .addCase(getLocations.pending, (state) => { 
                state.locations = []; 
                state.loading = true;
            })
            .addCase(getLocations.fulfilled, (state, action) => {
                state.loading = false;
                state.locations = action.payload.data; 
            }) 
            .addCase(getGlobeData.pending, (state) => { 
                state.globeData.data = []; 
                state.globeData.loading = true;
            })
            .addCase(getGlobeData.fulfilled, (state, action) => {
                state.globeData.loading = false;
                state.globeData.data = action.payload.data; 
            }) 
    }
});
 
export default locationsSlice.reducer;
