import React from 'react';
import Sidebar from '../components/Sidebar';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isScreenSmall } from '../utility/utils';
import { SmoothScrollTypes } from '../utility/constants';
import useSmoothScroll from '../utility/hooks/useSmoothScroll';

const DashboardLayout = () => { 
    const { isSidebarOpen, currentBreakPoint } = useSelector(state => state.navbar);  
    useSmoothScroll(SmoothScrollTypes.other)   
    return (
        <div className="flex min-h-screen">
            <Sidebar classNames=''/>
            <div className={`main-content-wrapper transition-all ease-in flex justify-start items-start flex-col ${isSidebarOpen ? ' sidebar-open ' : ' sidebar-close'} ${ isScreenSmall(currentBreakPoint)  ? ' small-screen ' : ' big-screen ' }`}>
                <Outlet />
            </div>
        </div>
    );
};

export default DashboardLayout;
