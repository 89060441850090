import { useEffect } from "react";
import { SmoothScrollTypes } from "../constants";

const baseScrollConfig = {
    accelerationMax: 3,
    keyboardSupport: true,
    arrowScroll: 50,
    pulseAlgorithm: true,
    pulseScale: 4,
    pulseNormalize: 1,
    touchpadSupport: false,
    fixedBackground: true,
    excluded: ''
};

const scrollConfig = {
  [SmoothScrollTypes.other]: { 
    animationTime: 400,
    stepSize: 60,
    accelerationDelta: 60
  },
  [SmoothScrollTypes.blackLayout]: {  
    animationTime: 600,
    stepSize: 30,
    accelerationDelta: 40
  }
};

const useSmoothScroll = (scrollType = SmoothScrollTypes.other) => { 
  useEffect(() => { 
    const smoothScrollOptions = { ...baseScrollConfig, ...scrollConfig[scrollType] }; 
    if (window.SmoothScroll) {
        window.SmoothScroll(smoothScrollOptions);
    } 
  }, [scrollType]);  
};

export default useSmoothScroll;
