// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';  
  
export const navbarSlice = createSlice({
    name: 'navbar',
    initialState: { 
        isSidebarOpen: true,
        currentBreakPoint : 'xl'
    },
    reducers: {
        handleWindowResize : (state, action) => { 
            state.isSidebarOpen = action.payload.isSidebarOpen
            state.currentBreakPoint = action.payload.currentBreakPoint
        },
        handleSidebarOpen : (state) => {
            state.isSidebarOpen = !state.isSidebarOpen
        }
    } 
}); 
export const { handleWindowResize , handleSidebarOpen} = navbarSlice.actions;

export default navbarSlice.reducer;
