import React, { Suspense, lazy } from 'react';
import Loader from '../components/Loader';

const Login = lazy(() => import('../pages/Auth/Login'));
const PasswordReset = lazy(() => import('../pages/Auth/PasswordReset'));
const SignUp = lazy(() => import('../pages/Auth/SignUp'));
const ResetPasswordFormScreen = lazy(() => import('../pages/Auth/ResetPasswordFormScreen'));
const VerifyEmail = lazy(() => import('../pages/Auth/Verifymail'));
const AuthLayout = lazy(() => import('../Layouts/AuthLayout'));
 
const authRoutes = [
    { path: '/login', element: <Suspense fallback={<Loader />}><Login /></Suspense> },
    { path: '/signup', element: <Suspense fallback={<Loader />}><SignUp /></Suspense> },
    { path: '/passwordreset', element: <Suspense fallback={<Loader />}><PasswordReset /></Suspense> },
    { path: '/verify-email', element: <Suspense fallback={<Loader />}><VerifyEmail /></Suspense> },
    { path: '/reset-password', element: <Suspense fallback={<Loader />}><ResetPasswordFormScreen /></Suspense> }
];

export default [
    {
        path: '/',
        element: <AuthLayout />,
        children: [...authRoutes]
    }
]
