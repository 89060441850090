import React, { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import Loader from '../components/Loader';
const HelpSos = lazy(() => import('../pages/BlackPages/HelpSos'));
const Career = lazy(() => import('../pages/BlackPages/Career'));
const FAQPage = lazy(() => import('../pages/BlackPages/FAQPage'));
const GetInTouch = lazy(() => import('../pages/BlackPages/GetInTouch'));
const Home = lazy(() => import('../pages/BlackPages/Home'));
const AboutUs = lazy(() => import('../pages/BlackPages/AboutUs'));
const BlackLayout = lazy(() => import('../Layouts/BlackLayout')); 
const PrintProfiler = lazy(() => import('../pages/Profiler/PrintProfiler'))
const ShareProfilerPage = lazy(() => import('../pages/Profiler/PrintProfiler/ShareProfilerPage'))

  
export const PrintPublicRoutes = [
  { path: '/print/:type', element: <Suspense fallback={<Loader />}><PrintProfiler /></Suspense> },
  { path: '/share/:type', element: <Suspense fallback={<Loader />}><ShareProfilerPage /></Suspense> }, 
] 

export default [
  {
    path: '/',
    element: (
      <Suspense fallback={<Loader isLogoWhite={false} />}>
        <BlackLayout>
          <Outlet />
        </BlackLayout>
      </Suspense>
    ),
    children: [
      { index: true, element: <Suspense fallback={<Loader style='min-h-max' isLogoWhite={true} />}><Home /></Suspense> },
      { path: 'about-us', element: <Suspense fallback={<Loader style='min-h-max' isLogoWhite={true} />}><AboutUs /></Suspense> },
      { path: 'faqs', element: <Suspense fallback={<Loader style='min-h-max' isLogoWhite={true} />}><FAQPage /></Suspense> },
      { path: 'career', element: <Suspense fallback={<Loader style='min-h-max' isLogoWhite={true} />}><Career /></Suspense> },
      { path: 'get-in-touch', element: <Suspense fallback={<Loader style='min-h-max' isLogoWhite={true} />}><GetInTouch /></Suspense> },
      { path: 'help-sos', element: <Suspense fallback={<Loader style='min-h-max' isLogoWhite={true} />}><HelpSos /></Suspense> },
    ],
  },
  ...PrintPublicRoutes
];
