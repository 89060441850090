// ** Auth Endpoints
//
const baseUrl = `${process.env.REACT_APP_API}/api`;
// const baseUrl = 'https://240a-49-43-98-54.ngrok-free.app/api';
export default {
    loginEndpoint: `${baseUrl}/auth/login`,
    registerEndpoint: `${baseUrl}/auth/register`,
    refreshEndpoint: `${baseUrl}/auth/refresh-token`,
    logoutEndpoint: `${baseUrl}/auth/logout`,
    facebookEndpoint: `${baseUrl}/auth/facebook-auth`,
    googleEndpoint: `${baseUrl}/auth/google-auth`,
    questions: `${baseUrl}/home/questions`,
    updateProfile: `${baseUrl}/home/settings`,
    updateQuestions: `${baseUrl}/home/answers`,
    recoverPassword: `${baseUrl}/auth/request-password-reset`,
    verifyEmail: `${baseUrl}/auth/verify-email`,
    resetPasswordSubmit: `${baseUrl}/auth/reset-password`,
    profile: `${baseUrl}/home/profile`,
    updateProfileImage: `${baseUrl}/home/profile-picture`,
    // ** This will be prefixed in authorization header with token
    // ? e.g. Authorization: Bearer <token>
    tokenType: 'Bearer',

    // ** Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken',
    rememberme: 'rememberme',
    storageRefreshTokenKeyName: 'refreshToken',
    userData: 'userData',
    selectedUserData : 'selectedUserData',
    scaleData : `${baseUrl}/home/house-count`,
    locations  : `${baseUrl}/home/location`, 
    saveHouse : `${baseUrl}/home/house`,
    saveSelectedCareers : `${baseUrl}/home/careers`,
    setupProfile :  `${baseUrl}/home/setup-profile`,
    globeData  :  `${baseUrl}/home/globe-data`,

    //admin api routes
    adminUsers :  `${baseUrl}/admin/user-list`,
    userDetails :  `${baseUrl}/admin/user`,
    deleteUser :  `${baseUrl}/admin/user`,
};
