// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchData } from '../utility/utils';
import useJwt from '../Auth/jwt/useJwt';
import jwtDefaultConfig from '../Auth/jwt/jwtDefaultConfig';


export const getResult = createAsyncThunk('profiler/getResult', async ({ answerString, isManually }, { rejectWithValue }) => {
    try {
        const response = await fetchData(`/ws/mnm/interestprofiler/results?answers=${answerString}`);
        if (response?.data?.result?.length > 0) {

            const answerPayload = { answers: response.data.result };
            await useJwt.submitQuestions(answerPayload);

            const userData = useJwt.getUserData()
            const rememberme = localStorage.getItem(jwtDefaultConfig.rememberme);
            const newUserData = { ...userData, user_answer: response.data.result, is_profile_completed: true };
            useJwt.setUserData(newUserData, rememberme);
            return {
                data: response.data.result,
                isManually: isManually
            };

        } else {
            return {
                data: [],
                isManually: false
            };
        }
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
})

export const submitManualScore = createAsyncThunk('profiler/submitManualScore', async ({ answers, isManually }, { rejectWithValue }) => { 
    try {
        const answerPayload = { answers };
        await useJwt.submitQuestions(answerPayload); 

        const rememberme = localStorage.getItem(jwtDefaultConfig.rememberme);
        const userData = useJwt.getUserData();
        const newUserData = { ...userData, user_answer: answers, is_profile_completed: true };
        useJwt.setUserData(newUserData, rememberme);
        return {
            data: answers,
            isManually: isManually
        };
    }catch (error) {
        return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
})


export const getOurScaleData = createAsyncThunk('profiler/getOurScaleData', async (_, { rejectWithValue }) => {
    try {
        const response = await useJwt.getScaleData();
        const data = response?.data?.data || {};
        return {
            data: data
        };
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
});


export const getJobZones = createAsyncThunk('profiler/getJobZones', async (_, { rejectWithValue }) => {
    try { 
        const response = await fetchData('/ws/mnm/interestprofiler/job_zones') 
        const data = response?.data?.job_zone || ''
        return {
            data: data
        }
    } catch (error) { 
        return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
})

export const profilerSlice = createSlice({
    name: 'profiler',
    initialState: {
        scores: [],
        scoreLoading: false,
        ourScaleData: {},
        manually: false,
        jobZonesData: {
            loading: false,
            data: []
        }
    },
    reducers: {
        enterScores: (state, action) => {
            state.scores = action.payload.data
            state.manually = action.payload.isManually
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getJobZones.pending, (state) => {
                state.jobZonesData.data = []
                state.jobZonesData.loading = true;
            })
            .addCase(getJobZones.fulfilled, (state, action) => {
                state.jobZonesData.data = action.payload.data
                state.jobZonesData.loading = false;
            })
            .addCase(getResult.pending, (state) => {
                state.scores = [];
                state.scoreLoading = true;
            })
            .addCase(getResult.fulfilled, (state, action) => {
                state.scores = action.payload.data;
                state.scoreLoading = false;
                state.manually = action.payload.isManually
            })
            .addCase(getResult.rejected, (state) => {
                state.scoreLoading = false;
                state.scores = []
                state.manually = false
            })
            .addCase(getOurScaleData.pending, (state) => {
                state.ourScaleData = {};
            })
            .addCase(getOurScaleData.fulfilled, (state, action) => {
                state.ourScaleData = action.payload.data;
            })
            .addCase(getOurScaleData.rejected, (state) => {
                state.ourScaleData = {}
            })
            .addCase(submitManualScore.pending, (state) => {
                state.scores = [];
                state.scoreLoading = true;
            })
            .addCase(submitManualScore.fulfilled, (state, action) => {
                state.scores = action.payload.data;
                state.scoreLoading = false;
                state.manually = action.payload.isManually
            })
            .addCase(submitManualScore.rejected, (state) => {
                state.scoreLoading = false;
                state.scores = []
                state.manually = false
            })
    }
});
export const { enterScores } = profilerSlice.actions;

export default profilerSlice.reducer;
